import { computed, observable, action } from "mobx";
import { route } from "preact-router";
export const KEY_STORAGE = "XO0jvliyngURoyqeCNYsQ2vNE5G9LMGD";

class UserMobx {
  @observable isLogin = true;
  @observable listTeam = [];
  @observable TeamNo = "";
  @observable TeamName = "";
  @observable Token = "";
  @observable GroupTeam = "";
  @observable listForPO = [];
  @observable currentPath = "/";

  constructor() {
    this.onInitial();
  }

  @action
  async onInitial() {
    try {
      let storage = await localStorage.getItem(KEY_STORAGE);
      if (storage) {
        const parse = JSON.parse(storage);
        for (const key in parse) {
          if (parse.hasOwnProperty(key)) {
            const element = parse[key];
            this[key] = element;
          }
        }
      }
    } catch (error) {
      console.log("ERROR", error);
    }
  }

  @action
  async onLogin(userInfo) {
    try {
      const { isLogin, listTeam, TeamNo, TeamName, Token, GroupTeam } =
        userInfo;
      const obj = { isLogin, listTeam, TeamNo, TeamName, Token, GroupTeam };

      const hash = JSON.stringify(obj);

      await localStorage.setItem(KEY_STORAGE, hash);

      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          const element = obj[key];
          this[key] = element;
        }
      }

      if (
        TeamNo === "mgr2022" ||
        TeamNo === "sls2022" ||
        TeamNo === "ceo2023"
      ) {
        route("/dashboard");
      } else if (TeamNo === "pur2022") {
        route("/prJobs");
      } else if (TeamNo === "mch2022") {
        route("/machineJobs");
      } else if (TeamNo === "qc2022") {
        route("/qcJobs");
      } else if (TeamNo === "hr2022") {
        route("/allJobs");
      } else if (TeamNo === "ac2023") {
        route("/accountingPage");
      } else {
        route("/serviceJobs");
      }
    } catch (error) {
      console.log(error);
    }
  }

  @action
  async onLogout() {
    try {
      await localStorage.clear();

      this.TeamNo = "";
      this.TeamName = "";
      this.access_token = "";
      this.GroupTeam = "";

      route("/account/login");
    } catch (error) {
      console.log(error);
      window.location.href = "";
    }
  }

  @action
  async onSetListPO(list) {
    try {
      this.listForPO = list;

      route("/detailPO");
    } catch (error) {
      console.log(error);
    }
  }
  @action
  async onSetCurrentPath(url) {
    try {
      this.currentPath = url;
    } catch (error) {
      console.log(error);
    }
  }

  @computed
  get returnCurrentPath() {
    return this.currentPath;
  }

  @action
  async onRemoveListPO() {
    try {
      this.listForPO = [];
    } catch (error) {
      console.log(error);
    }
  }

  @computed
  get returnListPO() {
    return this.listForPO;
  }

  @computed
  get onCheckLogin() {
    return this.isLogin;
  }

  @computed
  get returnToken() {
    return this.Token;
  }

  @computed
  get returnTeam() {
    return this.listTeam;
  }

  @computed
  get returnDisabled() {
    const userAllow = ["hr2022", "mgr2022", "sls2022", "ceo2023"];
    const allow = userAllow.findIndex((x) => x === this.TeamNo);
    return allow === -1;
  }

  @action
  async returnTeamNo(team) {
    let filterByName = this.listTeam.filter((x) => x.TeamName === team);
    if (filterByName.length > 0) {
      return filterByName[0].TeamNo;
    } else {
      let filterByNo = this.listTeam.filter((x) => x.TeamNo === team);
      if (filterByNo.length > 0) {
        return filterByNo[0].TeamNo;
      }
    }
  }
}

export default new UserMobx();
