import { create } from "apisauce";
import UserMobx from "../mobx/UserMobx";
//export const ip = "http://192.168.1.111:2800/SYSTECH/v1";
//export const ip = "http://192.168.31.246:2800/SYSTECH/v1";

export const ip = 'https://systech-management.com:2096/SYSTECH/v1'
const api = create({
  baseURL: ip,
  withCredentials: true,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 60000, // 10 seconds
});

/**
 * ฟังก์ชั่นสำหรับ ยิงข้อมูล
 * @param {string} path
 * @param {{}} obj
 * @return {{}}
 */

export const POST = (path, obj, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: token,
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

export const POST_FORMDATA = (path, obj, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    api
      .post(path, obj, {
        headers: {
          Authorization: token,
          "Content-Type": "multipart/form-data",
          type: "formData",
        },
      })
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });

/**
 * ฟังก์ชั่นสำหรับ ดึงข้อมูล
 * @param {string} path
 *
 */
export const GET = (path, token = UserMobx.returnToken) =>
  new Promise((resolve, reject) => {
    api
      .get(
        path,
        {},
        {
          headers: {
            Authorization: token,
          },
        }
      )
      .then((response) => {
        if (response.ok) {
          resolve(response.data);
        } else {
          response.data
            ? reject(response.data)
            : reject({ success: false, message: response.problem });
        }
      })
      .catch((err) => reject(err));
  });
/* ################################################## URL ################################################## */

export const LOGIN = `/authen/login`;
export const GET_USER_TEAM = `/authen/getUserTeam`;
export const GET_JOB_DASHBOARD = `/job/getDashBoard`;
export const GET_JOB_ALLJOB = `/job/getAllJob`;
export const GET_JOB_DETAIL = (jobNo) => `/job/getDetailJob?JobNo=${jobNo}`;
export const POST_JOB_DELETE = (jobNo) => `/job/deleteJob?JobNo=${jobNo}`;
export const GET_JOB_OPTION = `/job/getOptionCreateJob`;
export const GET_JOB_ADDFILE = `/job/addFileJob`;
export const GET_JOB_LOCATION = (customerNo) =>
  `/customer/getCustomerLocationContract?CustomerNo=${customerNo}`;
export const POST_JOB_CREATE = `/job/createJob`;
export const POST_JOB_EDIT = `/job/editJob`;
export const POST_JOB_SENDTEAM = `/job/sendValueToTeam`;
export const POST_JOB_REMOVE_FILE = `/job/removeFileJob`;
export const GET_QTY_DETAIL = (jobNo) => `/job/getTagJobByJobID?JobNo=${jobNo}`;
export const GET_QTY_ADDNEWTAG = `/job/addTagJob`;
export const POST_DELETE_TAG = (tagNo) => `/job/deleteTag?TagNo=${tagNo}`;
export const GET_QUOTATION_DETAIL = (quotation) =>
  `/quotation/getQuotation?QuotationNo=${quotation}`;
export const POST_QUOTATION_EDIT = `/quotation/editQuotation`;
export const POST_QUOTATION_CREATE = `/quotation/createQuotation`;
export const GET_SERVICE_ALL = `/service/getAllService`;
export const GET_SERVICE_DETAIL = (jobNo) =>
  `/service/getDetailService?JobNo=${jobNo}`;
export const POST_SERVICE_ADD = `/service/addService`;
export const POST_SERVICE_SCOPE = `/job/editScopeOfWork`;
export const GET_PR_ALL = `/purchase/getPRJob`;
export const GET_PR_DETAIL = (prNo) =>
  `/purchase/getDetailPR?PurchaseReqNo=${prNo}`;
export const GET_PR_UPDATE = `/purchase/updatePR`;
export const GET_PR_CREATE_MANUAL = `/purchase/createPRManual`;
export const GET_PR_UPDATE_STATUS = `/purchase/updateStatusPR`;
export const GET_PR_UPDATE_SERVICE = `/purchase/updatePRService`;
export const POST_PR_REMOVE_FILE = `/purchase/removePRFile`;
export const GET_PR_BY_JOB = (jobNo) => `/purchase/getPRByJobNo?JobNo=${jobNo}`;
export const GET_PO_ALL = `/purchase/getAllPO`;
export const GET_PO_DETAIL = (poNo) => `/purchase/getDetailPO?PONo=${poNo}`;
export const GET_PO_CREATE = `/purchase/createPO`;
export const GET_PO_UPDATE = `/purchase/updateDetailPO`;
export const UPDATE_PO_LINE = `/purchase/updatePOItem`;
export const POST_PO_REMOVE_FILE = `/purchase/removePOImageGR`;
export const GET_MACHINE_ALL = `/machine/getMachineJob`;
export const GET_MACHINE_DETAIL = (jobNo) =>
  `/machine/getDetailMachineJob?JobNo=${jobNo}`;
export const GET_MACHINE_UPDATE_STATUS = `/machine/updateStatusMachine`;
export const GET_MACHINE_PIC = (machineJobNo) =>
  `/machine/getMachinePic?MachineNo=${machineJobNo}`;
export const POST_MACHINE_ADD_PIC = `/machine/addMachinePic`;
export const POST_MACHINE_REMOVE_PIC = `/machine/removeMachinePic`;
export const POST_MACHINE_UPDATE = `/machine/editMachine`;
export const GET_QC_ALL = `/qc/getAllQCJob`;
export const GET_QC_DETAIL = `/qc/getDetailQC`;
export const GET_QC_ADD_FILE = `/qc/addQCFile`;
export const GET_QC_REMOVE_FILE = `/qc/removeQCFile`;
export const GET_TAG_QC_FILE = (tagNo) => `/qc/getQCTagFile?TagNo=${tagNo}`;
export const GET_TAG_QC_ADD_FILE = `/qc/addQCTagFile`;
export const GET_TAG_QC_UPDATE_STATUS = `/qc/updateQCTag`;
export const POST_TAG_QC_REMOVE_FILE = `/qc/removeQCTagFile`;
export const GET_JOB_FILE = (jobNo, type) =>
  `/job/getJobFiles?JobNo=${jobNo}&Type=${type}`;
export const POST_EDIT_FILE_SERVICE = `/service/editAttachedFilesService`;

export const SEND_NOTIFITATION = `/notification/sendNotiApprove`;
export const DELETE_SALE = (saleNo) => `/sale/deleteSale?SaleNo=${saleNo}`;
export const DELETE_CUSTOMER = (customerNo) =>
  `/customer/deleteCustomer?CustomerNo=${customerNo}`;
export const DELETE_CUSTOMER_CONTACT = `/customer/deleteCustomerContract`;

export const DELETE_TECHNICIAN = (TechnicianNo) =>
  `/technician/deleteTechnician?TechnicianNo=${TechnicianNo}`;

export const GET_SUMMARIZE_QTBY_YEAR = (Year) =>
  `/job/getSaleOrderBoookingByYear?Year=${Year}`;
export const GET_SUMMARIZE_COST_YEAR = (Year) =>
  `/job/getQuotationCostByYear?Year=${Year}`;
export const GET_SUMMARIZE_QTBY_DATE = (StartDate, EndDate) =>
  `/job/getChartQTByDate?StartDate=${StartDate}&EndDate=${EndDate}`;
export const GET_SUMMRIZE_POBY_DATE = (StartDate, EndDate) =>
  `/job/getChartPOByDate?StartDate=${StartDate}&EndDate=${EndDate}`;
export const GET_SUMMARIZE_SALEPERSON_YEAR = (Year) =>
  `/job/getSalePersonByYear?Year=${Year}`;

export const GET_ACCOUNTING_ALL = `/accounting/getAllJobAccounting`;
export const POST_SEND_TO_ACCOUNTING = (jobNo) =>
  `/accounting/sendToAccountingTeam?JobNo=${jobNo}`;
export const DELETE_JOB_ACCOUNT = `/accounting/delAccountingByJob`;

export const GET_SIGNATURE_SALE = (saleNo) =>
  `/sale/getSignaturePicSale?SaleNo=${saleNo}`;
export const POST_REMOVE_SIGNATURE = (saleNo) =>
  `/sale/removeSignaturePicSale?SaleNo=${saleNo}`;
export const POST_ADD_SIGNATURE = `/sale/addSignaturePicSale`;

export const GET_ALL_VENDER = `/vender/getAllVender`;
export const DELETE_VENDER = (venderNo) =>
  `/vender/deleteVender?VenderNo=${venderNo}`;
export const UPDATE_JOB_ACCOUNT = `/accounting/updateAccountingByJob`;
