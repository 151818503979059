import { Route, route, Router } from 'preact-router'
import RootRoute from './routes/Root'
import AuthenticatedRoute from './routes/AuthenticatedRoute'
import NotfoundScene from './screens/NotfoundScene'
import AccountRoute from './routes/AccountRoute'

const App = () => {
  function handleRouter(e) {
    //   const allowed = UserMobx.onCheckLogin
    //   if (!UserMobx.Token || !allowed) {
    //     if (e.url.indexOf('account') >= 0) {
    //       return route(e.url)
    //     }
    //     return route('/account/login')
    //   }
    //   if (e.url.indexOf('/account/') >= 0) return route('/')
  }

  return (
    <div>
      <Router onChange={e => handleRouter(e)}>
        <AccountRoute path='/account/:rest*' />
        <AuthenticatedRoute
          path='/:rest*'
          login='/login'
          route={RootRoute}
        />
        <Route path='*' default component={NotfoundScene} />
      </Router>
    </div>

  )
}

export default App
